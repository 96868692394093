import React from "react";
import footerlogo from "../../Assets/BeaconBVILogo.png";
import footerlogoapp from "../../Assets/apptext.png";
import "./Footer.css";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="footerHolder">
          <div className="birdlogo">
            <span>
              Hummingbird Health Inc. Copyright &copy;{new Date().getFullYear()}{" "}
              All rights reserved
            </span>
          </div>

          <div className="copyRight">
            <span className="paddingADJF">
              <img className="footerimgadj" src={footerlogo} />
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
